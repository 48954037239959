@keyframes orderTableKeyFrame {
    from {background-color: #f34d2f}
    to {}
}

@keyframes orderTableSellGreenFrame {
    from {background-color: #3bac51}
    to {}
}

@keyframes orderTableBuyRedFrame {
    from {background-color: #ff4e1f}
    to {}
}

@keyframes orderTableYellowKeyFrame {
    from {background-color: #ff703a}
    to {}
}